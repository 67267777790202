import React from "react";
import Footer from "../components/Footer";

const Disclimar = () => {
  return (
    <>
      <div className="mt-28 mb-10 lg:mx-40 mx-8 lg:p-12 p-8 shadow-2xl">
        <h1 className="text-2xl  font-medium">Disclaimer</h1>
        <h2 className="mt-2">
          The information contained in this website is for general information
          purposes only. The information is provided by www.surajsolution.com a
          property of SURAJ SOLUTION. While we endeavor to keep the information
          up to date and correct, we make no representations of any kind,
          express or implied, about the completeness, accuracy, reliability,
          suitability or availability with respect to the website or the
          information, products, services, or related graphics contained on the
          website for any purpose. Any reliance you place on such information is
          therefore strictly at your own risk.
        </h2>
        <h2 className="mt-2">
          In no event will we be liable for any loss or damage including without
          limitation, indirect or consequential loss or damage, or any loss or
          damage whatsoever arising from loss of data or profits arising out of,
          or in connection with, the use of this website.
        </h2>
        <h2 className="mt-2">
          Through this website you are able to link to other websites which are
          not under the control of SURAJ SOLUTION. We have no control over the
          nature, content and availability of those sites. The inclusion of any
          links does not necessarily imply a recommendation or endorse the views
          expressed within them.
        </h2>
        <h2 className="mt-2">
          Every effort is made to keep the website up and running smoothly.
          However, SURAJ SOLUTION takes no responsibility for, and will not be
          liable for, the website being temporarily unavailable due to technical
          issues beyond our control.
        </h2>

        <h1 className="text-2xl  font-medium mt-8">Contents:</h1>
        <li>Views expressed</li>
        <li>No responsibility</li>
        <li>Past performance</li>
        <li>Use at your own risk</li>
        <li>Errors and omissions</li>
        <li>Copyright notice</li>
      </div>
      <Footer />
    </>
  );
};

export default Disclimar;
